import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import './Hero.scss'
import ayalaLogo from '../../../assets/images/about-us/AyalaLandInternational.png'
import GridLogo from '../../GridLogo/GridLogo'

const Hero = ({ heroImage, heroTitle }: any): JSX.Element => {
  const aboutUsHeroBanner = useStaticQuery(graphql`
    query {
      heroBanner(title: { eq: "About Us" }) {
        image
      }
    }
  `)
  const image = aboutUsHeroBanner.heroBanner ? aboutUsHeroBanner.heroBanner.image : ''
  const bannerImage = heroImage || image
  const title = heroTitle || ''

  return (
    <>
      <div className="about-us-hero-banner" />

      <div className="about-us-banner-container">
        <div className="about-us-hero-media">
          <img className="fade-in" src={bannerImage} alt="hero banner" />
        </div>
      </div>

      <div className="about-us-hero-mask-2" />
      <div className="about-us-hero-mask" />
      <div className="about-us-hero-grid">
        <GridLogo />
      </div>
      <div className="about-us-hero-header">
        <div className="about-us-header-wrapper">
          <div className="filler" />
          <div className="content">
            <img src={ayalaLogo} alt="ayala land logo" />
            <h1>{title}</h1>
          </div>
          <div className="filler" />
        </div>
      </div>
    </>
  )
}

export default Hero
