import React, { ReactElement } from 'react'
import AboutUsLayout from '../../../components/Layout/AboutUs'

import SEO from '../../../components/SEO/SEO'

import MissionAndVision from '../../../components/AboutUsPage/MissionAndVision'

const AboutUsPage = ({ pageContext: { links } }: any): ReactElement => {
  return (
    <AboutUsLayout>
      <SEO title="MISSION AND VISION - ALISI | Ayala Land" />
      <MissionAndVision links={links} />
    </AboutUsLayout>
  )
}

export default AboutUsPage
