/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import { navigate } from '@reach/router'

import './ContentNav.scss'

const ContentNav = (props: any): JSX.Element => {
  const { links, isActive } = props
  const isLinks: any = links && links

  const [active, setActive] = useState(isActive)

  return (
    <div className="content-nav-container">
      {isLinks &&
        isLinks.map((link: { name: string; url: string }, index: number) => {
          const tempActive = active === index ? 'active' : ''
          return (
            <div
              key={link.url}
              onClick={(): any => navigate(link.url)}
              className={`content-nav-button ${tempActive}`}
              onMouseEnter={(): void => setActive(index)}
              onMouseLeave={(): void => setActive(isActive)}
            >
              {link.name}
            </div>
          )
        })}
    </div>
  )
}

export default ContentNav
