import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import ContentNav from '../../ContentNav'
import Content from '../../Content'

const MissionAndVision = ({ links }: any): JSX.Element => {
  const missionAndVisionData = useStaticQuery(graphql`
    query {
      missionAndVision {
        id
        name
        slug
        containers {
          name
          field_collections {
            name
            fields {
              name
              value
            }
          }
        }
      }
    }
  `)

  const visionContainer = missionAndVisionData.missionAndVision.containers[0].field_collections

  const vision = {
    title: visionContainer ? visionContainer[0].fields[0].value : '',
    body: visionContainer && visionContainer[1] ? visionContainer[1].fields && visionContainer[1].fields.map((res: any) => res.value) : [],
    images: visionContainer && visionContainer[2] ? visionContainer[2].fields && visionContainer[2].fields.map((res: any) => res.value) : []
  }

  const visionBody =
    vision.body &&
    vision.body.map((res: any, index: number) => {
      return <p key={index}>{res}</p>
    })

  const visionImages =
    vision.images &&
    vision.images.map((res: any, index: number) => {
      return <img className="image-cover" src={res} alt="mission" key={index} />
    })

  const missionContainer = missionAndVisionData.missionAndVision.containers[1]
    ? missionAndVisionData.missionAndVision.containers[1].field_collections
    : null

  const mission = {
    title: missionContainer ? missionContainer[0].fields[0].value : '',
    body: missionContainer ? missionContainer[1].fields && missionContainer[1].fields.map((res: any) => res.value) : [],
    images: missionContainer ? missionContainer[2].fields && missionContainer[2].fields.map((res: any) => res.value) : []
  }

  const missionBody = mission.body &&
    mission.body.map((res: any, index: any) => {
      return <p key={index}>{res}</p>
    })

  const missionImages = mission.images &&
          mission.images.map((res: any, index: any) => {
            if (res.value !== 'No Data' && res.value) {
              return <img className="image-cover" src={res.value} key={index} />
            }
          })

  return (
    <>
      <ContentNav links={links} active={1} />
      <Content>
        <h1>Mission and Vision</h1>
        <h2>{mission.title && `${mission.title}:`}</h2>
        {missionBody}
        {missionImages}
        <h2>{vision.title}:</h2>
        {visionBody}
        {visionImages}
      </Content>
    </>
  )
}

export default MissionAndVision
