import React from 'react'

import './ContentContainer.scss'

const ContentContainer = (props: any) => {
  return (
    <div className="page-container">
      <div className="page-wrapper">
        <div className="content-paper">{props.children}</div>
      </div>
    </div>
  )
}

export default ContentContainer
