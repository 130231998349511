import React, { ReactElement } from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import './Layout.scss'
import './Animations.scss'
import Hero from '../AboutUsPage/Hero'
import ContentContainer from '../ContentContainer'

type LayoutPropTypes = {
  children?: string | Element | ReactElement | boolean | (string | Element | ReactElement | boolean)[]
  heroImage?: string
}

const AboutUsLayout = ({ children, heroImage }: LayoutPropTypes): ReactElement => (
  <>
    <Header />
    <Hero heroImage={heroImage} />
    <ContentContainer>{children}</ContentContainer>
    <Footer />
  </>
)

export default AboutUsLayout
